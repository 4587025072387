import React, { useRef } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import { StaticImage } from "gatsby-plugin-image"

const styles = createStyles({
  metaStarCTA: {
    display: "flex",
    position: "relative",
    cursor: "pointer"
  },
  logo: {
    width: 200,
    objectFit: "contain"
  },
  arrowContainer: {
    position: "absolute",
    top: 28,
    transform: "translateX(45px)",
    transition: "transform .5s ease-in-out"
  },
  arrow: {
    width: 123,
    objectFit: "contain",
  },
})

export interface Props {
  url: string;
}

type PropsAndStyles = Props & WithStyles<typeof styles>


const MetastartCTA = ({ classes, url }: PropsAndStyles) => {
  const arrowRef = useRef(null)

  const onMouseEnter = () => {
    if (arrowRef.current) {
      arrowRef.current.style.transform = "translateX(149px)"
    }
  }
  const onMouseLeave = () => {
    if (arrowRef.current) {
      arrowRef.current.style.transform = "translateX(45px)"
    }
  }

  return (
    <a
      className={classes.metaStarCTA}
      href={url}
      target="_blank"
    >
      <div ref={arrowRef} className={classes.arrowContainer}>
        <StaticImage className={classes.arrow} placeholder="none" src="../images/metastar/arrow.png" alt="" />
      </div>
      <StaticImage className={classes.logo} placeholder="none" src="../images/metastar/logo.png" alt="" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
    </a>
  )
}

export default withStyles(styles)(MetastartCTA)
