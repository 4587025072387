import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import { Link } from "gatsby"
import Socials from "./socials"
import LogoSVG from "../images/logo-white.svg"
import ArrowUp from "../images/icons/arrow-up.svg"

const styles = createStyles({
  footer: {
    background: "#EFEEF0",
  },
  content: {
    maxWidth: 880,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    padding: 70,
    "@media (max-width:599.95px)": {
      padding: 20,
      flexDirection: "column",
    },
  },
  navigationBlock: {
    display: "flex",
    flexDirection: "column",
    width: 160,
    fontSize: "0.78rem",
    lineHeight: "1rem",
    "@media (max-width:599.95px)": {
      width: "100%",
      marginTop: 10,
      textAlign: "center",
    },
  },
  navigationItem: {
    color: "#898989",
    textDecoration: "none",
    marginBottom: 15,
    "&:hover": {
      color: "#4B4B4B",
    },
  },
  navigationTitle: {
    fontWeight: 700,
    lineHeight: "1.11rem",
    color: "#606060",
  },
  socials: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    "@media (max-width:599.95px)": {
      justifyContent: "center",
      marginBottom: 20,
    },
  },
  divider: {
    width: "100%",
    height: 100,
    background: "#494949",
  },
  dividerContent: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    maxWidth: 880,
  },
  logo: {
    position: "relative",
    top: -12,
    padding: 12,
    paddingBottom: 0,
    background: "#494949",
    borderRadius: 10,
  },
  returnButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#212121",
    color: "#727272",
    width: "46px",
    height: "46px",
    border: "none",
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: 8,
    outline: "none",
    cursor: "pointer",
  },
  footNote: {
    fontFamily: "Montserrat",
    height: 70,
    background: " #212121",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.83rem",
    lineHeight: "1.33rem",
    color: "rgba(119, 119, 119, 0.8)",
    padding: "0 10px",
  },
})

export interface Props {}

type PropsAndStyles = Props & WithStyles<typeof styles>

const Header = ({ classes }: PropsAndStyles) => (
  <footer className={classes.footer}>
    <div className={classes.content}>
      <div className={classes.navigation}>
        <div className={classes.navigationBlock}>
          <Link
            to="/story/"
            className={classes.navigationItem}
            activeClassName={classes.navigationTitle}
          >
            OUR STORY
          </Link>
          <Link
            to="/games/"
            className={classes.navigationItem}
            activeClassName={classes.navigationTitle}
          >
            GAMES
          </Link>
          <Link
            to="/team/"
            className={classes.navigationItem}
            activeClassName={classes.navigationTitle}
          >
            TEAM
          </Link>
          <Link
            to="/careers/"
            className={classes.navigationItem}
            activeClassName={classes.navigationTitle}
          >
            CAREERS
          </Link>
          <Link
            to="/contact/"
            className={classes.navigationItem}
            activeClassName={classes.navigationTitle}
          >
            CONTACT
          </Link>
        </div>

        {/* <div className={classes.navigationBlock}>
          <Link
            to="/"
            className={classNames(
              classes.navigationItem,
              classes.navigationTitle
            )}
          >
            PROJECT INFORMATION
          </Link>
          <Link to="/" className={classes.navigationItem}>
            SUBMIT A PROJECT
          </Link>
          <Link to="/" className={classes.navigationItem}>
            APPLY
          </Link>
          <Link to="/" className={classes.navigationItem}>
            PARTNERSHIPS
          </Link>
        </div> */}

        <div className={classes.navigationBlock}>
          <div
            className={classNames(
              classes.navigationItem,
              classes.navigationTitle
            )}
          >
            CONTACT INFORMATION
          </div>
          <div className={classes.navigationItem}>Lisbon, Portugal</div>
          <div className={classes.navigationItem}>info@voltgames.io</div>
          <div className={classNames(classes.navigationItem, classes.socials)}>
            <Socials color="#898989" size={24} />
          </div>
        </div>
      </div>
    </div>
    <div className={classes.divider}>
      <div className={classes.dividerContent}>
        <div className={classes.logo}>
          <LogoSVG />
        </div>

        <button
          className={classes.returnButton}
          onClick={() => window.scrollTo(0, 0)}
        >
          <ArrowUp />
        </button>
      </div>
    </div>

    <div className={classes.footNote}>
      © Volt Games - All rights reserved 2021. We love our players!
    </div>
  </footer>
)

export default withStyles(styles)(Header)
