import React, { useState, useEffect } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Hidden } from "@material-ui/core"
import LogoSVG from "../images/logo.svg"
import TriangleSVG from "../images/metastar/triangle.svg"
import MetaStarCTA from "./metastarCTA"
import BurguerIcon from "./burguer-icon"
// import CallToAction from "./call-to-action"

const styles = createStyles({
  header: {
    height: 100,
    padding: "0 40px",
    maxWidth: 960,
    margin: "auto",
    position: "relative",
    "@media (max-width: 959.95px)": {
      padding: "0 20px",
    },
    "@media (max-width: 599.95px)": {
      height: "auto",
    },
  },
  main: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  },
  logo: {
    paddingTop: 20,
  },
  linksContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 959.95px)": {
      order: 1
    },
  },
  link: {
    fontSize: "0.78rem",
    textAlign: "center",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "#898989",
    padding: "0 15px",
    lineHeight: "1rem",
    "&:hover": {
      color: "#4B4B4B",
    },
    "@media (max-width: 959.95px)": {
      padding: "0 10px",
    },
  },
  activeLink: {
    fontWeight: 700,
    color: "#FF7900",
  },
  expandableMenu: {
    overflow: "hidden",
    transition: "height 0.5s ease-in-out",
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    zIndex: 1
  },
  expandableMenuLinksContainer: {
    height: 131,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFFFF"
  },
  metastarContainer: {
    paddingRight: 50,
    position: "relative",
    top: 44,
    zIndex: 1,
    "@media (max-width: 959.95px)": {
      paddingRight: 0,
      order: 0
    },
    "@media (max-width: 674.95px)": {
      transform: "scale(0.8)",
      position: "absolute",
      left: 100,
      top: 37
    },
  },
  metastarMobile: {
    position: "relative",
    display: "flex",
    flexDirection: "column"
  },
  metastarMobileBackground: {
    height: 86,
    width: "100%",
    background: "#FFFFFF"
  },
  metastarMobileBackgroundTriangle: {
    width: "100%",
    height: "auto"
  },
  metastarMobileComponentContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) scale(0.8)"
  }
})

export interface Props { }

type PropsAndStyles = Props & WithStyles<typeof styles>

const Header = ({ classes }: PropsAndStyles) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            metastar {
              enabled
              url
            }
          }
        }
      }
    `
  )

  const [expanded, setExpanded] = useState(false)

  const toggleMenu = () => {
    setExpanded(!expanded)
  }

  const resetExpanded = () => {
    if (window.innerWidth >= 960) {
      setExpanded(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", resetExpanded)

    return () => {
      window.removeEventListener("resize", resetExpanded)
    }
  }, [])

  return (
    <header className={classes.header}>
      <div className={classes.main}>
        <Link to="/" className={classes.logo}>
          <LogoSVG />
        </Link>

        <Hidden xsDown>
          <div className={classes.linksContainer}>
            <Link
              to="/story/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Our Story
            </Link>
            <Link
              to="/games/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Games
            </Link>
            <Link
              to="/team/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Team
            </Link>
            <Link
              to="/careers/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Careers
            </Link>
            <Link
              to="/contact/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Contact
            </Link>
          </div>

          {site.siteMetadata?.metastar?.enabled &&
            <div className={classes.metastarContainer}>
              <MetaStarCTA url={site.siteMetadata.metastar.url} />
            </div>}
        </Hidden>

        <Hidden smUp>
          <BurguerIcon open={expanded} onClick={toggleMenu} />
        </Hidden>
      </div>

      <Hidden smUp>
        <div
          style={{ height: expanded ? 310 : 0 }}
          className={classes.expandableMenu}
        >
          <div className={classes.expandableMenuLinksContainer}>
            <Link
              to="/story/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Our Story
            </Link>
            <Link
              to="/games/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Games
            </Link>
            <Link
              to="/team/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Team
            </Link>
            <Link
              to="/careers/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Careers
            </Link>
            <Link
              to="/contact/"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Contact
            </Link>
          </div>

          {site.siteMetadata?.metastar?.enabled &&
            <div className={classes.metastarMobile}>
              <div className={classes.metastarMobileBackground} />
              <TriangleSVG className={classes.metastarMobileBackgroundTriangle} />
              <div className={classes.metastarMobileComponentContainer}>
                <MetaStarCTA url={site.siteMetadata.metastar.url} />
              </div>
            </div>}
        </div>
      </Hidden>
    </header>
  )
}

export default withStyles(styles)(Header)
